export const registrationStatus = {
  EmailNotVerified: 1,
  EmailVerified: 2,
  BasicInformationCompleted: 3,
  AggrementApproved: 4,
  DetailInformationCompleted: 5,
};

export const userStatus = {
  Waiting: 1,
  Approved: 2,
  Rejected: 3,
  Revoked: 4,
};

export const contributerImageStatus = {
  Pending: 1,
  Approved: 2,
  Rejected: 3,
};
