/* eslint-disable */
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Layout, Button } from 'antd';
import SplashScreen from 'components/LayoutComponents/SplashScreen';
import { useCookies } from 'react-cookie';
import styles from './style.module.scss';
import json from '../../../package.json';

const LoginLayout = memo(({ children }) => {
  const [cookies, setCookie] = useCookies(['adzy-hide-splashscreen']);
  const [splashScreenOpen, setSplashScreenOpen] = useState(true);

  const toggleOpenCloseSplashScreen = useCallback(() => {
    setSplashScreenOpen((open) => {
      if (open) {
        setCookie('adzy-hide-splashscreen', 'true');
        setSplashScreenOpen(false);
      }
      return !open;
    });
  }, []);

  return (
    <Layout>
      <Layout.Content>
        <div
          className={`${styles.layout} ${styles.light}`}
          style={{
            backgroundImage: "url('resources/images/photos/8.jpeg')",
          }}>
          <div className={styles.content}>{children}</div>
          <div className={`${styles.footer} `}>
            <div>
              <a href="https://adzy.co/creator-hub-terms-of-service/" target="_blank">
                Adzy Creator Hub Terms of Service
              </a>
            </div>
            <div>
              Enigma Innovations Group Limited &copy; {new Date().getFullYear()} &nbsp; | &nbsp; v{json.version} &nbsp;
            </div>
            <div>
              <a href="https://adzy.co/privacy-policy/" target="_blank">
                Privacy policy
              </a>
            </div>
          </div>
          {/* {cookies['adzy-hide-splashscreen'] === "true" ? null : <SplashScreen open={splashScreenOpen} toggleOpenCloseModal={toggleOpenCloseSplashScreen} />} */}
        </div>
      </Layout.Content>
    </Layout>
  );
});

export default LoginLayout;
