const actions = {
  SET_STATE: 'myMedia/SET_STATE',
  SET_UPLOADING_STATE: 'myMedia/SET_UPLOADING_STATE',
  GET_MYMEDIA: 'myMedia/GET_MYMEDIA',
  POST_MEDIA: 'myMedia/POST_MEDIA',
  DELETE_MEDIA_DOCUMENT: 'myMedia/DELETE_MEDIA_DOCUMENT',
  DELETE_MEDIA: 'myMedia/DELETE_MEDIA',
  POST_DOCUMENTS: 'myMedia/POST_DOCUMENTS',
  ADD_NEWMEDIA_CONTAINER: 'myMedia/ADD_NEWMEDIA_CONTAINER',
  PUSH_NEWMEDIA: 'myMedia/PUSH_NEWMEDIA',
  PUSH_NEWMEDIAS: 'myMedia/PUSH_NEWMEDIAS',
  REMOVE_NEWMEDIA: 'myMedia/REMOVE_NEWMEDIA',
  REMOVE_ALL_NEWMEDIA: 'myMedia/REMOVE_ALL_NEWMEDIA',
  PUSH_NEWMEDIA_DOCS: 'myMedia/PUSH_NEWMEDIA_DOCS',
  REMOVE_NEWMEDIA_DOC: 'myMedia/REMOVE_NEWMEDIA_DOC',
  REMOVE_ALL_NEWMEDIA_DOC: 'myMedia/REMOVE_ALL_NEWMEDIA_DOC',
  SAVE_ALL: 'myMedia/SAVE_ALL',
};

export default actions;
