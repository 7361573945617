import React from 'react';
import { notification } from 'antd';

export function displayErrorMessage(title, messages) {
  notification.error({
    message: title,
    description: (
      <div style={{ maxHeight: 200, overflowY: 'auto' }}>
        {messages.map((x, i) => (
          <div key={i}>
            {x}
            <br />
          </div>
        ))}
      </div>
    ),
    duration: 600,
  });
}
