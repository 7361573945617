import { all, takeEvery, put, call, delay } from 'redux-saga/effects';
import { notification } from 'antd';
import actions from './actions';
import { apiCall, l10n } from 'services/utils';

export function* DOWNLOAD_AD({ payload }) {
  yield put({
    type: 'ads/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const response = yield call(() =>
    apiCall({
      method: 'POST',
      url: 'Campaign/downloadAd',
      data: { id: payload.id },
      responseType: 'blob',
    }),
  );
  if (response.success) {
    try {
      var blob = new Blob([response.value], { type: 'application/pdf' });
      const shareData = {
        title: 'ADZY - ' + payload.title,
        //text: payload.desc,
        //url: 'https://adzy.co',
        files: [new File([blob], 'Adzy.pdf', { type: blob.type })],
      };
      navigator.share(shareData);
    } catch (error) {
      notification.error({
        message: l10n.error,
        description: error,
        duration: 0,
      });

      const link = document.createElement('a');
      link.target = '_blank';
      link.download = 'Adzy.pdf';
      link.href = URL.createObjectURL(new Blob([response.value], { type: 'application/pdf' }));
      link.click();
    }
  }
  yield put({
    type: 'ads/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* BUY({ payload }) {
  yield put({
    type: 'ads/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const response = yield call(() =>
    apiCall({
      method: 'POST',
      url: 'Campaign/buy',
      data: payload,
      responseType: 'blob',
    }),
  );
  console.log('final', response);
  if (response.success) {
    const link = document.createElement('a');
    link.target = '_blank';
    link.download = 'Adzy.zip';
    link.href = URL.createObjectURL(new Blob([response.value], { type: 'application/zip' }));
    link.click();

    yield put({
      type: 'campaign/GET',
      payload: payload.id,
    });
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    });
  }
  yield put({
    type: 'ads/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* GET_MYADS() {
  yield put({
    type: 'ads/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const response = yield call(() =>
    apiCall({
      method: 'POST',
      url: 'Campaign/myAds',
    }),
  );
  if (response.success) {
    yield put({
      type: 'ads/SET_STATE',
      payload: {
        myliveads: response.value,
      },
    });
  }

  yield put({
    type: 'ads/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* CREATE_AD({ payload }) {
  yield put({
    type: 'ads/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const response = yield call(() =>
    apiCall({
      method: 'POST',
      url: 'Campaign/save',
      data: payload,
    }),
  );
  if (response.success) {
    yield put({
      type: 'ads/SET_STATE',
      payload: {
        loading: false,
        actionStatus: 'adCreated',
      },
    });
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    });
  }
}

export function* SEND_AD({ payload }) {
  yield put({
    type: 'ads/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const response = yield call(() =>
    apiCall({
      method: 'POST',
      url: 'Campaign/sendAd',
      data: payload,
    }),
  );
  if (response.success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    });
  }
  yield put({
    type: 'ads/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* GET_PRIMARYTEXTS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });
  const response = yield call(() =>
    apiCall({
      method: 'POST',
      url: 'Campaign/primaryTexts',
      data: {
        sectorId: payload.sectorId,
      },
    }),
  );
  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        primaryTexts: response.value,
      },
    });
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  });
}

export function* GET_ADS() {
  yield put({
    type: 'ads/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const response = yield call(() =>
    apiCall({
      method: 'POST',
      url: 'Campaign/initial',
      data: payload,
    }),
  );
  if (response.success) {
    yield put({
      type: 'ads/SET_STATE',
      payload: {
        data: response.value,
      },
    });
  }

  yield put({
    type: 'ads/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* GET_ADIMAGES({ payload }) {
  yield put({
    type: 'ads/SET_STATE',
    payload: {
      loading: true,
      adSetNo: payload.adSetNo,
      ['dataAdSet' + payload.adSetNo]: [],
    },
  });
  var start = new Date().getTime();
  const response = yield call(() =>
    apiCall({
      method: 'POST',
      url: 'Campaign/initial',
      data: payload.form,
    }),
  );
  var dummyDelay = start - new Date().getTime() + 4000;
  if (dummyDelay > 0) yield delay(dummyDelay);
  if (response.success) {
    yield put({
      type: 'ads/SET_STATE',
      payload: {
        ['dataAdSet' + payload.adSetNo]: response.value.ads,
        campaign: response.value.campaign,
      },
    });
  }

  yield put({
    type: 'ads/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ADS, GET_ADS),
    takeEvery(actions.CREATE_AD, CREATE_AD),
    takeEvery(actions.BUY, BUY),
    takeEvery(actions.SEND_AD, SEND_AD),
    takeEvery(actions.DOWNLOAD_AD, DOWNLOAD_AD),
    takeEvery(actions.GET_ADIMAGES, GET_ADIMAGES),
    takeEvery(actions.GET_MYADS, GET_MYADS),
    takeEvery(actions.GET_PRIMARYTEXTS, GET_PRIMARYTEXTS),
  ]);
}
