import React from 'react';
import styles from './style.module.scss';
import json from '../../../../package.json';

const Footer = () => (
  <div className={styles.footer}>
    <a href="https://adzy.co/creator-hub-terms-of-service/" target="_blank" rel="noopener noreferrer">
      Adzy Creator Hub Terms of Service
    </a>
    <div>&copy; Enigma Innovations Group Limited {new Date().getFullYear()} &nbsp; | &nbsp; v{json.version} &nbsp;</div>
    <a href="https://adzy.co/privacy-policy/" target="_blank" rel="noopener noreferrer">
      Privacy Policy
    </a>
  </div>
);

export default Footer;
