import { all } from 'redux-saga/effects';
import user from './user/sagas';
import users from './users/sagas';
import menu from './menu/sagas';
import settings from './settings/sagas';
import transactions from './transactions/sagas';
import purposes from './purposes/sagas';
import reasons from './reasons/sagas';
import sectors from './sectors/sagas';
import subSectors from './subSectors/sagas';
import emotions from './emotions/sagas';
import linkDescriptions from './linkDescriptions/sagas';
import images from './images/sagas';
import dashboard from './dashboard/sagas';
import ads from './ads/sagas';
import myAds from './myAds/sagas';
import algos from './algo/sagas';
import campaigns from './campaigns/sagas';
import primaryTexts from './primarytexts/sagas';
import payment from './payment/sagas';
import userImage from './userImage/sagas';
import campaign from './campaign/sagas';
import mediaLibrary from './mediaLibrary/sagas';

export default function* rootSaga() {
  yield all([
    user(),
    users(),
    menu(),
    settings(),
    purposes(),
    reasons(),
    sectors(),
    subSectors(),
    emotions(),
    linkDescriptions(),
    images(),
    transactions(),
    dashboard(),
    ads(),
    myAds(),
    algos(),
    campaigns(),
    primaryTexts(),
    payment(),
    userImage(),
    campaign(),
    mediaLibrary(),
  ]);
}
