const actions = {
  SET_STATE: 'payment/SET_STATE',
  GET_FEATURES: 'payment/GET_FEATURES',
  GET_PAYMENTS: 'payment/GET_PAYMENTS',
  SUBSCRIBE: 'payment/SUBSCRIBE',
  CANCEL: 'payment/CANCEL',
  CREATE_SETUP_INTENT: 'payment/CREATE_SETUP_INTENT',
  CARD_LIST: 'payment/CARD_LIST',
  CARD_REMOVE: 'payment/CARD_REMOVE',
  CARD_MAKE_DEFAULT: 'payment/CARD_MAKE_DEFAULT',
  PAYMENT_HISTORY_LIST: 'payment/PAYMENT_HISTORY_LIST',
  SUBSCRIPTION_LIST: 'payment/SUBSCRIPTION_LIST',
  INVOICE_LIST: 'payment/INVOICE_LIST',
};

export default actions;
