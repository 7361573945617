import { all, takeEvery, put, call } from 'redux-saga/effects';
import { notification } from 'antd';
import amplifyService from 'services/awsamplify';
import { history } from 'index';
import { processResponse } from 'services/utils';
import { usersApi } from 'services/user';
import actions from './actions';
import { apiCall, convertModelToFormData, gtmLogLogout, encodeBase64 } from '../../services/utils';

export function* LOGIN({ payload }) {
  const { email, password } = payload;

  yield put({
    type: 'user/RESET',
  });

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });

  const response = yield call(usersApi.login, { email, password });

  if (processResponse(response)) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    });
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}
export function* REGISTER({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });

  const response = yield call(usersApi.registerAccount, payload);
  // 207: ContributerEmailShouldVerify
  if (processResponse(response, true) || response.code == 207) {
    yield call(history.push, {
      pathname: '/verification',
      state: history.location && history.location.state,
      search: '?e=' + encodeBase64(payload.email),
    });
  } else if (response.code == 206) {
    // 206: EmailAlreadyExist
    yield call(history.push, {
      pathname: '/login',
      state: history.location && history.location.state,
      search: '?e=' + encodeBase64(payload.email),
    });
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* LOAD_CURRENT_ACCOUNT() {
  const token = localStorage.getItem('token');
  if (token) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: true,
      },
    });
    const response = yield call(usersApi.getAccountData);
    if (processResponse(response, true)) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          ...response.value,
          plan: response.value.plan,
          feature: response.value.feature,
          authorized: true,
          version: new Date().getTime(),
          // registrationStatus: 4,
        },
      });
    }
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    });
  }
}

export function* LOAD_TOP_ADS() {
  const token = localStorage.getItem('token');
  if (token) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: true,
      },
    });
    const response = yield call(usersApi.getTopAds);
    if (processResponse(response, true)) {
      yield put({
        type: 'users/SET_TOP_ADS',
        payload: response.value,
      });
    }
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    });
  }
}

export function* LOAD_STATS() {
  const token = localStorage.getItem('token');
  if (token) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: true,
      },
    });
    const response = yield call(usersApi.getStats);
    if (processResponse(response, true)) {
      yield put({
        type: 'users/SET_STATS',
        payload: response.value,
      });
    }
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    });
  }
}

export function* LOGOUT() {
  //yield call(amplifyService.logout);
  localStorage.removeItem('token');
  localStorage.removeItem('uid');
  yield put({
    type: 'user/RESET',
  });
  localStorage.removeItem('introVideo');
  gtmLogLogout();
}

export function* FORGOT_PASSWORD({ payload }) {
  const { email } = payload;

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });

  const response = yield call(() =>
    apiCall({
      url: 'Account/recover',
      data: { email },
    }),
  );

  if (response.success) {
    yield call(history.push, {
      pathname: '/forgot-password-final',
      state: history.location && history.location.state,
      search: '?e=' + encodeBase64(payload.email),
    });
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}
export function* FORGOT_PASSWORD_SUBMIT({ payload }) {
  const { email, code, password } = payload;

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });

  const response = yield call(() =>
    apiCall({
      url: 'Account/recoverDone',
      data: { email, code, password },
    }),
  );

  if (response.success) {
    yield call(history.push, {
      pathname: '/login',
      state: history.location && history.location.state,
      search: '?e=' + encodeBase64(payload.email),
    });
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}
export function* CHANGE_PASSWORD({ payload }) {
  const { oldPassword, newPassword } = payload;

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });

  const response = yield call(usersApi.changePassword, { oldPassword, newPassword });
  if (processResponse(response)) {
    notification.success({
      message: 'Success',
      description: `Your account was succesfully recovered`,
    });

    yield call(history.push, {
      pathname: '/login',
      state: history.location && history.location.state,
    });
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* VERIFY_SENDCODE({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });

  const response = yield call(() =>
    apiCall({
      method: 'POST',
      url: 'Account/resendVerification',
      data: payload,
    }),
  );
  if (response.success) {
    yield call(history.push, {
      pathname: '/verification',
      state: history.location && history.location.state,
      search: '?e=' + encodeBase64(payload.email),
    });
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* VERIFY_CODE({ payload }) {
  const { email, code } = payload;

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });

  const response = yield call(() =>
    apiCall({
      method: 'POST',
      url: 'Account/verifyCode',
      data: { email, code },
    }),
  );
  if (response.success) {
    yield call(history.push, {
      pathname: '/login',
      state: history.location && history.location.state,
    });
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* UPDATE_USER({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const response = yield call(usersApi.update, payload);
  if (response && response._id) {
    yield put({
      type: 'user/GET_USERS',
    });
    notification.success({
      message: 'Success',
      description: 'User updated',
    });
    yield call(history.push, {
      pathname: '/users',
      state: history.location && history.location.state,
    });
  } else {
    notification.error({
      message: 'Could not update data',
      description: 'System could not communicate properly with the endpoints',
    });
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}
export function* SEND_FEEDBACK({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const response = yield call(usersApi.sendFeedback, payload);
  if (processResponse(response)) {
    notification.success({
      message: 'Success',
      description: 'Thank you for your feedback',
    });
  } else {
    notification.error({
      message: 'Could not send feedback',
      description: 'System could not communicate properly with the endpoints',
    });
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* CREATE_USER({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const response = yield call(usersApi.create, payload);
  if (response && response.status && response.status !== 'BAD') {
    yield put({
      type: 'user/GET_USERS',
    });
    notification.success({
      message: 'Success',
      description: 'User created',
    });
    yield call(history.push, {
      pathname: '/users',
      state: history.location && history.location.state,
    });
  } else {
    notification.error({
      message: 'Could not create data',
      description: (response && response.message) || 'System could not communicate properly with the endpoints',
    });
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* DELETE_USER({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const response = yield call(usersApi.delete, payload);
  if (response && response.status) {
    yield put({
      type: 'users/GET_USERS',
    });
    notification.success({
      message: 'Success',
      description: 'User deleted',
    });
    yield call(history.push, '/users');
  } else {
    notification.error({
      message: 'Could not delete sector',
      description: 'System could not communicate properly with the endpoints',
    });
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* UPDATE_ACCOUNT({ payload }) {
  const { data, forceReload } = payload;
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const response = yield call(usersApi.updateAccount, data);
  if (processResponse(response, true)) {
    notification.success({
      message: 'Success',
      description: 'User updated',
    });
    if (forceReload) {
      window.location.reload();
    } else {
      yield put({
        type: 'user/LOAD_CURRENT_ACCOUNT',
      });
    }
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}
export function* UPDATE_ACCOUNT_SECTOR({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const response = yield call(usersApi.updateUserSector, payload);
  if (processResponse(response, true)) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    });
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}
export function* UPDATE_ACCOUNT_COMPANY({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  //const response = yield call(usersApi.updateUserCompany, payload);
  var formData = convertModelToFormData(payload, '');
  const response = yield call(() =>
    apiCall({
      url: 'User/updateCompany',
      data: formData,
      timeout: 600000,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  );

  if (response.success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    });
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
  if (response.success && payload.redirectHome) {
    yield call(history.push, {
      pathname: '/',
      state: history.location && history.location.state,
    });
  }
}

export function* UPDATE_CONTRIBUTOR_DETAILS_BASIC({ payload }) {
  const { data, forceReload } = payload;
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const response = yield call(() =>
    apiCall({
      url: 'Contributer/basic',
      data,
      method: 'PUT',
    }),
  );
  if (processResponse(response, true)) {
    notification.success({
      message: 'Success',
      description: 'Your details successfully updated',
    });
    if (forceReload) {
      window.location.reload();
    } else {
      yield put({
        type: 'user/LOAD_CURRENT_ACCOUNT',
      });
    }
    setTimeout(() => {
      window.location.href = '/#/';
    }, 1000);
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* UPDATE_CONTRIBUTOR_DETAILS_EXTENDET({ payload }) {
  const { data, forceReload } = payload;
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const response = yield call(() => apiCall({ url: 'Contributer/detail', data, method: 'PUT' }), data);
  if (processResponse(response, true)) {
    notification.success({
      message: 'Success',
      description: 'Your details successfully updated',
    });
    if (forceReload) {
      window.location.reload();
    } else {
      yield put({
        type: 'user/LOAD_CURRENT_ACCOUNT',
      });
      window.location.href = '/#/';
    }
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* ACCEPT_CONTRIBUTOR_TERMS() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const response = yield call(() => apiCall({ url: 'Contributer/approve-agreement', method: 'PUT' }));
  if (processResponse(response, true)) {
    notification.success({
      message: 'Success',
      description: '',
    });
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    });
    window.location.href = '/#/';
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.GET_STATS, LOAD_STATS),
    takeEvery(actions.LOAD_TOP_ADS, LOAD_TOP_ADS),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.FORGOT_PASSWORD, FORGOT_PASSWORD),
    takeEvery(actions.FORGOT_PASSWORD_SUBMIT, FORGOT_PASSWORD_SUBMIT),
    takeEvery(actions.UPDATE_USER, UPDATE_USER),
    takeEvery(actions.SEND_FEEDBACK, SEND_FEEDBACK),
    takeEvery(actions.CREATE_USER, CREATE_USER),
    takeEvery(actions.DELETE_USER, DELETE_USER),
    takeEvery(actions.UPDATE_ACCOUNT, UPDATE_ACCOUNT),
    takeEvery(actions.UPDATE_ACCOUNT_SECTOR, UPDATE_ACCOUNT_SECTOR),
    takeEvery(actions.UPDATE_ACCOUNT_COMPANY, UPDATE_ACCOUNT_COMPANY),
    takeEvery(actions.CHANGE_PASSWORD, CHANGE_PASSWORD),
    takeEvery(actions.VERIFY_CODE, VERIFY_CODE),
    takeEvery(actions.VERIFY_SENDCODE, VERIFY_SENDCODE),
    takeEvery(actions.UPDATE_CONTRIBUTOR_DETAILS_BASIC, UPDATE_CONTRIBUTOR_DETAILS_BASIC),
    takeEvery(actions.UPDATE_CONTRIBUTOR_DETAILS_EXTENDET, UPDATE_CONTRIBUTOR_DETAILS_EXTENDET),
    takeEvery(actions.ACCEPT_CONTRIBUTOR_TERMS, ACCEPT_CONTRIBUTOR_TERMS),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ]);
}
