import React from 'react';
import classNames from 'classnames';
import styles from './style.module.scss';
import { Spin } from 'antd';

const Loader = ({ expand, isRouting }) =>
  // <div
  //   className={classNames(styles.loader, {
  //     [styles.hidden]: !spinning,
  //     [styles.fullScreen]: fullScreen,
  //   })}
  // />
  isRouting ? (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}>
      <Spin />
    </div>
  ) : (
    <div
      className={classNames(styles.loaderWrapper, {
        [styles.expand]: expand,
      })}>
      <div className={styles.loader}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );

export default Loader;
