import { all, takeEvery, put, call, delay, select } from 'redux-saga/effects';
import actions from './actions';
import { apiCall } from 'services/utils';
import { validateImage } from 'business/helpers/imageHelper';
import { displayErrorMessage } from 'business/helpers/appHelper';
import { notification } from 'antd';

export function* GET_MYMEDIA({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      dataLoading: true,
    },
  });
  //yield delay(5000);
  const response = yield call(() =>
    apiCall({
      method: 'GET',
      url: 'ContributerImage/Getv2?page=' + (payload?.page || 1),
    }),
  );

  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        data: response.value,
        hasMore: response.hasMore,
      },
    });
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      dataLoading: false,
    },
  });
}

export function* POST_MEDIA({ payload }) {
  const { tempId, image, documents, reloadData } = payload;

  if (!image) {
    return;
  }

  yield put({
    type: actions.SET_UPLOADING_STATE,
    payload: {
      [tempId]: true,
    },
  });

  // console.log('uploading', image.name);
  // yield delay(5000);

  const formData = new FormData();
  formData.append('file', image);

  const response = yield call(
    () =>
      apiCall({
        url: 'ContributerImage',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
        method: 'POST',
      }),
    formData,
  );

  if (response.success && documents.length > 0) {
    yield all(
      documents.map((document) => {
        const formData = new FormData();
        formData.append('file', document);
        return call(
          () =>
            apiCall({
              url: `ContributerImage/${response.value.id}/documents`,
              data: formData,
              headers: { 'Content-Type': 'multipart/form-data' },
              method: 'POST',
            }),
          formData,
        );
      }),
    );
  }

  yield put({
    type: actions.REMOVE_NEWMEDIA,
    payload: tempId,
  });

  if (reloadData) {
    yield put({
      type: actions.GET_MYMEDIA,
    });
  }
}

export function* POST_DOCUMENTS({ payload }) {
  const { imageId, documents, reloadData } = payload;

  yield put({
    type: actions.SET_UPLOADING_STATE,
    payload: {
      [imageId]: true,
    },
  });

  // yield delay(10000);

  if (documents.length > 0) {
    yield all(
      documents.map((document) => {
        const formData = new FormData();
        formData.append('file', document);
        return call(
          () =>
            apiCall({
              url: `ContributerImage/${imageId}/documents`,
              data: formData,
              headers: { 'Content-Type': 'multipart/form-data' },
              method: 'POST',
            }),
          formData,
        );
      }),
    );
  }

  yield put({
    type: actions.SET_UPLOADING_STATE,
    payload: {
      [imageId]: false,
    },
  });

  if (reloadData) {
    yield put({
      type: actions.GET_MYMEDIA,
    });
  }
}

export function* SAVE_ALL({ payload }) {
  var state = (yield select()).mediaLibrary;

  const newMediaToUpload = { ...state.newMediaToUpload };
  // remove empty items
  for (const key in newMediaToUpload) {
    if (!newMediaToUpload[key] && state.newMediaDocsToUpload[key].length == 0) {
      delete newMediaToUpload[key];
    }
  }

  // no need to continue
  if (Object.keys(newMediaToUpload).length == 0) {
    notification.warning({
      message: 'Please add at least one media!',
    });
    return;
  }

  state.newMediaToUpload = newMediaToUpload;

  for (const tempId in state.newMediaToUpload) {
    if (!state.uploading[tempId]) {
      state.uploading[tempId] = 'pending';
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      savingAll: true,
      uploading: state.uploading,
      newMediaToUpload: state.newMediaToUpload,
    },
  });

  for (const tempId in state.newMediaToUpload) {
    state = (yield select()).mediaLibrary;
    if (state.savingAll == 'cancelling') {
      // console.log('cancelling');
      for (const tempId in state.newMediaToUpload) {
        state.uploading[tempId] = false;
      }
      yield put({
        type: actions.SET_STATE,
        payload: {
          savingAll: false,
          uploading: state.uploading,
        },
      });
      break;
    }

    if (state.uploading[tempId] != 'pending') continue;
    yield POST_MEDIA({
      payload: {
        tempId,
        image: state.newMediaToUpload[tempId],
        documents: state.newMediaDocsToUpload[tempId],
      },
    });
    yield put({
      type: actions.GET_MYMEDIA,
    });
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      savingAll: false,
    },
  });
}

export function* DELETE_MEDIA_DOCUMENT({ payload }) {
  const { imageId, documentId } = payload;

  yield put({
    type: actions.SET_UPLOADING_STATE,
    payload: {
      [imageId]: true,
    },
  });

  const response = yield call(() =>
    apiCall({
      url: `ContributerImage/${imageId}/documents/${documentId}`,
      method: 'DELETE',
    }),
  );

  yield put({
    type: actions.SET_UPLOADING_STATE,
    payload: {
      [imageId]: false,
    },
  });

  yield put({
    type: actions.GET_MYMEDIA,
  });
}

export function* DELETE_MEDIA({ payload }) {
  const { imageId } = payload;

  yield put({
    type: actions.SET_UPLOADING_STATE,
    payload: {
      [imageId]: true,
    },
  });

  const response = yield call(() =>
    apiCall({
      url: `ContributerImage/${imageId}`,
      method: 'DELETE',
    }),
  );

  yield put({
    type: actions.SET_UPLOADING_STATE,
    payload: {
      [imageId]: false,
    },
  });

  yield put({
    type: actions.GET_MYMEDIA,
  });
}

export function* PUSH_NEWMEDIAS({ payload }) {
  var state = (yield select()).mediaLibrary;
  var errors = {};
  for (const key in payload) {
    const item = payload[key];
    var res = yield call(() => validateImage(item.file).catch((err) => err));
    if (res != item.file) {
      if (!errors[res[0]]) errors[res[0]] = [];
      errors[res[0]].push(res[1]);
      continue;
    }
    state.newMediaToUpload[item.tempId] = item.file;
    if (!state.newMediaDocsToUpload[item.tempId]) {
      state.newMediaDocsToUpload[item.tempId] = [];
    }
  }

  if (Object.keys(errors).length > 0) {
    var messages = [];
    for (const key in errors) {
      messages.push(key + ':');
      errors[key].forEach((x) => {
        messages.push(x);
      });
    }
    displayErrorMessage('Image Validation', messages);
  }

  for (const key in state.newMediaToUpload) {
    if (!state.newMediaToUpload[key] && state.newMediaDocsToUpload[key].length == 0) {
      delete state.newMediaToUpload[key];
    }
  }

  if (Object.keys(state.newMediaToUpload).length == 0) {
    var key = new Date().getTime();
    state.newMediaToUpload[key] = null;
    state.newMediaDocsToUpload[key] = [];
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      newMediaToUpload: { ...state.newMediaToUpload },
      newMediaDocsToUpload: { ...state.newMediaDocsToUpload },
    },
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_MYMEDIA, GET_MYMEDIA),
    takeEvery(actions.POST_MEDIA, POST_MEDIA),
    takeEvery(actions.DELETE_MEDIA_DOCUMENT, DELETE_MEDIA_DOCUMENT),
    takeEvery(actions.DELETE_MEDIA, DELETE_MEDIA),
    takeEvery(actions.POST_DOCUMENTS, POST_DOCUMENTS),
    takeEvery(actions.SAVE_ALL, SAVE_ALL),
    takeEvery(actions.PUSH_NEWMEDIAS, PUSH_NEWMEDIAS),
  ]);
}
