import { API } from 'aws-amplify';
import { gtmLogLogin } from './utils';

export default {};

function setAccessToken(response) {
  if (response.success && response.value.accessToken) {
    localStorage.setItem('token', response.value.accessToken);
    localStorage.setItem('uid', response.value.uid);
    gtmLogLogin();
  }
  return response;
}

export const usersApi = {
  get() {
    return API.get('NODE_SERVICE_API', '/users')
      .then((response) => response)
      .catch((err) => err);
  },
  getTopAds() {
    return API.get('NODE_SERVICE_API', '/Contributer/topads')
      .then((response) => response)
      .catch((err) => {
        console.error(err);
      });
  },
  getStats() {
    return API.get('NODE_SERVICE_API', '/Contributer/stats')
      .then((response) => response)
      .catch((err) => {
        console.error(err);
      });
  },
  create(data) {
    return API.post('NODE_SERVICE_API', '/users', { body: data })
      .then((response) => response)
      .catch((err) => (err.response && err.response.data) || err);
  },
  update(data) {
    console.log(data);
    return API.put('NODE_SERVICE_API', `/users/${data.id}`, { body: data })
      .then((response) => response)
      .catch((err) => err);
  },
  delete(data) {
    return API.del('NODE_SERVICE_API', `/users/${data.id}`, { body: data })
      .then((response) => response)
      .catch((err) => err);
  },
  getAccountData(data) {
    return API.get('NODE_SERVICE_API', `/contributer/profile`, { body: data })
      .then((response) => response)
      .catch((err) => err);
  },
  updateAccount(data) {
    return API.put('NODE_SERVICE_API', `/User/update`, { body: data })
      .then((response) => response)
      .catch((err) => err);
  },
  updateUserSector(data) {
    return API.put('NODE_SERVICE_API', `/User/updateSector`, { body: data })
      .then((response) => response)
      .catch((err) => err);
  },
  updateUserCompany(data) {
    return API.put('NODE_SERVICE_API', `/User/updateCompany`, { body: data })
      .then((response) => response)
      .catch((err) => err);
  },
  sendFeedback(data) {
    return API.post('NODE_SERVICE_API', `/User/feedback`, { body: data })
      .then((response) => response)
      .catch((err) => err);
  },
  changePassword(data) {
    return API.post('NODE_SERVICE_API', `/Account/changePassword`, { body: data })
      .then((response) => response)
      .catch((err) => err);
  },
  registerAccount(data) {
    return API.post('NODE_SERVICE_API', `/Account/register`, { body: data })
      .then((response) => response)
      .catch((err) => err);
  },
  login(data) {
    return API.post('NODE_SERVICE_API', `/Account/login`, { body: data })
      .then(setAccessToken)
      .catch((err) => err);
  },
  verifyEmailCode(data) {
    return API.post('NODE_SERVICE_API', `/Account/verifyCode`, { body: data })
      .then(setAccessToken)
      .catch((err) => err);
  },
  recover(data) {
    return API.post('NODE_SERVICE_API', `/Account/recover`, { body: data });
  },
};
