import 'rc-drawer/assets/index.css';
import React, { memo, useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Drawer, Button, Modal, Row } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { registrationStatus, userStatus } from '../../utils/enums';

const AppMenu = memo(() => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user || {});
  const settings = useSelector((state) => state.settings || {});
  const { isMobileMenuOpen, isProfileMenuOpen, isFavoriteDrawerOpen } = settings;
  const closeAll = () => {
    dispatch({
      type: 'settings/SET_STATE',
      payload: {
        isProfileMenuOpen: false,
        isMobileMenuOpen: false,
        isFavoriteDrawerOpen: false,
      },
    });
  };

  const toggleOpen = useCallback(() => {
    dispatch({
      type: 'settings/SET_STATE',
      payload: {
        isFavoriteDrawerOpen: false,
        isProfileMenuOpen: false,
        isMobileMenuOpen: !isMobileMenuOpen,
      },
    });
  }, [isMobileMenuOpen]);

  const logout = useCallback(() => {
    dispatch({
      type: 'user/LOGOUT',
    });
  }, []);

  const goToFeedback = useCallback(() => {
    dispatch({
      type: 'settings/SET_STATE',
      payload: {
        isProfileMenuOpen: false,
        isMobileMenuOpen: false,
        isFavoriteDrawerOpen: false,
      },
    });
    return history.push('/users/feedback');
  }, []);

  const goToPage = (url) => () => {
    closeAll();
    return history.push(url);
  };

  const closeAccountHandler = () => {
    Modal.info({
      icon: null,
      content: (
        <div className="infoModalWrapper">
          <Row justify="center" align="middle" gutter={8}>
            <Row>
              <span className="title3 pt-4">
                Please email us{' '}
                <a style={{ textDecoration: 'underline' }} href="mailto:team@adzy.co?subject=Request account closure">
                  here
                </a>{' '}
                to request account closure.
              </span>
            </Row>
          </Row>
        </div>
      ),
      okButtonProps: { style: { display: 'none' } },
      closable: true,
      centered: true,
      footer: null,
      width: 300,
    });
  };

  return (
    <Drawer
      visible={isMobileMenuOpen}
      placement="right"
      maskClosable
      width="300px"
      closable={false}
      onClose={toggleOpen}
      className="user-webmenu">
      {isMobileMenuOpen && (
        <div className="top">
          <Button onClick={toggleOpen}>
            <CloseOutlined />
          </Button>
        </div>
      )}

      <div className="menu-content">
        <div className="welcome">
          <h3>Hello {user.name}</h3>
          <p>Nice to see you</p>
        </div>
        {!(user.status === userStatus.Rejected || user.status === userStatus.Revoked) && (
          <ul className="main-navigation">
            {(user.status !== userStatus.Rejected || user.status !== userStatus.Revoked) &&
              (user.registrationStatus === registrationStatus.BasicInformationCompleted ||
                user.registrationStatus === registrationStatus.DetailInformationCompleted) && (
                <li>
                  <Button onClick={goToPage('/dashboard')} className="menuTitle">
                    Dashboard
                  </Button>
                  <div className="infoText">
                    View current earnings & your platform stats, see your top sellers, discover what's trending, view
                    revenue history
                  </div>
                </li>
              )}

            <li>
              <Button onClick={goToPage('/your-details')} className="menuTitle">
                Your Details
              </Button>
              <div className="infoText">
                Edit name, change password, update contact details, set payment preferences
              </div>
            </li>
            {user.status === userStatus.Approved && (
              <>
                <li>
                  <Button onClick={goToPage('/media-library')} className="menuTitle">
                    Your Media Library
                  </Button>
                  <div className="infoText">Upload content, release documents, view media, edit details</div>
                </li>
                <li>
                  <Button onClick={goToPage('/payments')} className="menuTitle">
                    Payments
                  </Button>
                  <div className="infoText">View payment history</div>
                </li>
              </>
            )}
          </ul>
        )}

        <ul className="secondary-navigation">
          <li>
            <a target="_blank" href="https://adzy.tawk.help/">
              HELP & SUPPORT
            </a>
          </li>
          <li>
            <a target="_blank" href="https://adzy.tawk.help/">
              SEND FEEDBACK
            </a>
          </li>
          <li>
            <Button onClick={closeAccountHandler}>CLOSE ACCOUNT</Button>
          </li>
        </ul>
      </div>
      <div className="signOut mb30">
        <Button onClick={logout}>Sign out</Button>
      </div>
    </Drawer>
  );
});

export default AppMenu;
