import actions from './actions';

const initialState = {
  version: new Date().getTime(),
  address: {
    addressFirstLine: '',
    city: '',
    country: '',
    state: '',
    zipCode: '',
  },
  id: '',
  email: '',
  name: '',
  phone: '',
  status: '',
  isApproveAgreement: '',
  isVerifiedByEmail: '',
  payment: { payoutEmail: '' },
  role: '',
  avatar: '',
  picture: '',
  presence: {
    facebook: '',
    instagram: '',
    portfolio: '',
    website: '',
  },
  business_type: '',
  company: '',
  website: '',
  business_type: '',
  sector: '',
  createdAt: '',
  debug: false,
  forgotEmailCodeSent: false,
  authorized: false,
  loading: false,
  remainCredit: 0,
  plan: null,
  feature: null,
  statsOverview: {
    currentAdCreated: 0,
    totalAdCreated: 0,
    totalAdServed: 0,
    totalAdStockUploaded: 0,
    totalEarnings: 0,
    totalPayoutBalance: 0,
  },
  registrationStatus: 0,
  picture: '',
  contributerImageCount: 1,
  feature: '',
  topAds: [
    // { imageId: '5e9b0560140c27243406cf71', count: '10' },
    // { imageId: '5e9b059c140c27243406d0fe', count: '20' },
    // { imageId: '5e9b055f140c27243406cf6c', count: '30' },
  ],
  stats: [
    {
      id: '0',
      creatorId: '0',
      dateYear: 0,
      dateMonth: 0,
      adStockUploaded: 0,
      adServed: 0,
      adCreated: 0,
      earnings: 0,
      payoutBalance: 0,
    },
  ],
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.SET_TOP_ADS:
      state.topAds = action.payload;
      return state;
    case actions.SET_STATS:
      state.stats = action.payload;
      return state;
    case actions.RESET:
      return { ...initialState };
    default:
      return state;
  }
}
