import actions from './actions';

const initialState = {
  data: [],
  newMediaToUpload: {},
  newMediaDocsToUpload: {},
  dataLoading: null,
  loading: {},
  uploading: {},
  savingAll: false,
  cancelSavingAll: false,
  hasMore: false,
};

export default function mediaReducer(state = initialState, action) {
  // console.log('reducer1', action.type, action);
  // console.log('reducer2', state);
  switch (action.type) {
    case actions.RESET:
      return { ...initialState };
    case actions.ADD_NEWMEDIA_CONTAINER:
      var tempId = new Date().getTime();
      // insert item at the beginning of the object
      state.newMediaToUpload = { [tempId]: null, ...state.newMediaToUpload };
      state.newMediaDocsToUpload[tempId] = [];

      return {
        ...state,
        newMediaToUpload: { ...state.newMediaToUpload },
        newMediaDocsToUpload: { ...state.newMediaDocsToUpload },
      };
    case actions.PUSH_NEWMEDIA:
      state.newMediaToUpload[action.payload.tempId] = action.payload.file;
      if (!state.newMediaDocsToUpload[action.payload.tempId]) {
        state.newMediaDocsToUpload[action.payload.tempId] = [];
      }
      return {
        ...state,
        newMediaToUpload: { ...state.newMediaToUpload },
        newMediaDocsToUpload: { ...state.newMediaDocsToUpload },
      };
    // case actions.PUSH_NEWMEDIAS:
    //   action.payload.forEach((item) => {
    //     state.newMediaToUpload[item.tempId] = item.file;
    //     if (!state.newMediaDocsToUpload[item.tempId]) {
    //       state.newMediaDocsToUpload[item.tempId] = [];
    //     }
    //   });
    //   return {
    //     ...state,
    //     newMediaToUpload: { ...state.newMediaToUpload },
    //     newMediaDocsToUpload: { ...state.newMediaDocsToUpload },
    //   };
    case actions.REMOVE_NEWMEDIA:
      delete state.newMediaToUpload[action.payload];
      return { ...state, newMediaToUpload: { ...state.newMediaToUpload } };
    case actions.PUSH_NEWMEDIA_DOCS:
      action.payload.docs.forEach((item) => {
        state.newMediaDocsToUpload[action.payload.tempId].push(item);
      });
      return {
        ...state,
        newMediaDocsToUpload: { ...state.newMediaDocsToUpload },
      };
    case actions.REMOVE_NEWMEDIA_DOC:
      state.newMediaDocsToUpload[action.payload.tempId].splice(action.payload.index, 1);
      return {
        ...state,
        newMediaDocsToUpload: { ...state.newMediaDocsToUpload },
      };
    case actions.REMOVE_ALL_NEWMEDIA_DOC:
      state.newMediaDocsToUpload[action.payload.tempId] = [];
      return { ...state, newMediaDocsToUpload: { ...state.newMediaDocsToUpload } };
    case actions.REMOVE_ALL_NEWMEDIA:
      return { ...state, newMediaToUpload: {}, newMediaDocsToUpload: {} };
    case actions.SET_UPLOADING_STATE:
      Object.keys(action.payload).forEach((id) => {
        state.uploading[id] = action.payload[id];
      });
      return { ...state, uploading: state.uploading };
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
